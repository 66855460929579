.input_box{
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-direction: column; */
}
.title{
    color: white;
    margin:0;
    padding: 50px 0px;
    
}
.form-styling{
    height: 30px;
    width: 300px;
    border-radius: 10px;
}
.input-box{
    display: flex;
    flex-direction: column;

    /* gap: 2px; */
    /* background-color: red; */
}
.button-style{
    margin: auto;
    margin-top: 40px;
    height: 40px;
    width: 150px;
    align-self: center;
    justify-content: center;
    /* font-size: 25px; */
    /* background-color: #; */
    /* border: 20px solid #fff; */
    border-radius: 40px;
    padding: 5px;
    /* font-weight: lighter; */
}

.d-flex{
    display: flex;
    flex-direction: column;
}

.down{
    font-size: larger;
    font-weight: bolder;
    color: whitesmoke;
}
.add-page{
background-color: black;
min-height: 100vh;
}