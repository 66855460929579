/* AuthPage.css */

/* Container for the whole form */
.auth-page-container {
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 400px;
    padding: 2rem;
    text-align: center;
    transition: all 0.3s ease;
    margin: 2rem auto;
  }
  
  /* Header styling */
  .auth-page-header {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1.5rem;
    font-weight: bold;
  }
  
  /* Input fields */
  .auth-page-input {
    width: 100%;
    padding: 0.8rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
  }
  
  .auth-page-input:focus {
    border-color: #4f8ef7;
    outline: none;
  }
  
  /* Submit button */
  .auth-page-button {
    width: 100%;
    padding: 0.8rem;
    background-color: #4f8ef7;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .auth-page-button:hover {
    background-color: #3c7dd3;
  }
  
  /* Toggle text */
  .auth-page-toggle-text {
    margin-top: 1.5rem;
    font-size: 1rem;
    color: #666;
  }
  
  .auth-page-toggle-link {
    color: #4f8ef7;
    cursor: pointer;
    font-weight: bold;
  }
  
  .auth-page-toggle-link:hover {
    text-decoration: underline;
  }
  
  /* Error message */
  .auth-page-error {
    color: red;
    margin-top: 1rem;
    font-size: 1rem;
  }
  
  /* Success message */
  .auth-page-success {
    color: green;
    margin-top: 1rem;
    font-size: 1rem;
  }
  .mail{
    color: white;
  }