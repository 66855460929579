.qr-scanner {
    min-height: 700px;
    /* padding-top: 100px; */
    /* width: 80%; */
    margin: auto;
    background-color: black;
    color: white;
    /* display: flex; */
}
.navbar{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
}


    
.login-button{
    background-color: white;
    width: 100px;
    color: black;
    padding: 5px;
    
}
.upload-tickets {
    min-height: 580px;
    font-family: 'Roboto', sans-serif;
}

.custom-file {

    /* border: 1px solid rgb(0, 0, 0); */
    border-radius: 10px;
    width: 400px;
    margin: auto;
    margin-top: 50px;

}

.modify-button {
    background-color: rgb(0, 153, 153);
    padding: 5px 30px;
    color: white;
    /* width: 200px */
    border: none;
    border-radius: 20px;
    margin-top: 20px;
    font-size: 16px;
    margin-bottom: 100px ;
}


.scan>button {
    background-color: rgb(0, 153, 153);
    padding: 5px 10px;
    color: white;
    width: 100px;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    font-size: 20px;

}

.approve-result {
    font-size: 20px;
    text-align: left;
    margin-top: 10px ;
    margin-left: 32px;
}

.table-data>tr:nth-child(odd) {
    background-color: rgb(239, 239, 239);
}

table {
    width: 90%;
    max-width: 800px;
    margin: auto;
}

#html5-qrcode-button-camera-stop {
    background-color: rgb(0, 163, 163);
    border-radius: 10px;
    color: white;
    border: none;
    padding: 0px 10px;

}

.ticket-checkbox {
    font-size: 100px;
    color: rgb(0, 173, 0);
}

.scan {
    width: 100%;
    position: absolute;
    top: 70%;
}

.approved {
    display: flex;
    flex-direction: column;
}



.ticket-id {
    font-size: 12px;
    margin-top: 20px;
    margin: 0px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: rgb(65, 65, 65);
}

.approved {
    /* background: rgb(239, 239, 239); */
    width: 70%;
    font-size: 25px;
    color: green;
    margin: auto;
    border-radius: 15px;
    padding-top: 20px;
    padding-bottom: 10px;
}

.hide {
    visibility: hidden;
}

.error {
    color: red;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 0px;
}

.already-scanned {
    margin-top: 20px;
    color: red;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}